/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/* body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f0f4f8;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */

.uninstall {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8;
}

.container {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

h1 {
  font-size: 1.75rem;
  /* color: #2c3e50; */
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  /* color: #7f8c8d; */
  margin-bottom: 1.5rem;
}

label {
  font-size: 0.9rem;
  /* color: #34495e; */
  text-align: left;
  margin-bottom: 0.5rem;
  display: block;
}

select,
textarea,
input[type="file"] {
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid #dcdde1;
  font-size: 0.9rem;
  /* color: #2c3e50; */
  background-color: #f8f9fa;
}

textarea {
  resize: none;
  width: 95%;
}

/* Buttons styling */
.buttons {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.submit-btn {
  background-color: rgb(68, 134, 244);
  color: #fff;
}

.cancel-btn {
  background-color: #95a5a6;
  color: #fff;
}

button:hover {
  opacity: 0.8;
}
button:active {
  transform: scale(0.98);
}
.mainTextStyle {
  display: flex;
  justify-content: row, space-between;
  gap: 10px;
}
.icon_style {
  width: 10%;
  height: 10%;
  margin-right: 10px;
  margin-top: -5%;
  margin-right: 5%;
  margin-left: -5%;
}
