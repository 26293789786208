/* Base Styles */
@media (max-width: 600px) {
  .content {
    padding: 0 20px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.01em;
  text-align: center;
  overflow: auto;
  animation: fadeIn linear 1s;
  align-self: start;
  padding: 0px;
  /* padding-inline: 3%; */
  /* border: 1px solid white; */
  /* border-radius: 20px; */
  align-items: center;
  justify-content: center;
}

/* Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

/* Main Page Home */
.main_page_home {
  background: rgb(120, 232, 255);
  background: radial-gradient(
    circle,
    rgba(120, 232, 255, 1) 16%,
    rgba(242, 120, 255, 1) 63%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  width: 100%;
  overflow: hidden;
}

.main_page_home_text {
  width: 100%;
  margin-top: 13%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  padding-top: 5%;
}

.main_page_home_titletext {
  width: 55%;
  text-align: center;
  color: #000;
  font-size: 3.6rem;
  font-weight: 700;
  letter-spacing: 0.001em;
  line-height: 1.1;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 60px;
  animation: fadeInUp linear 1s;
}

.main_page_home_linkdiv {
  display: flex;
  width: 21%;
  margin: 25px auto 0;
  background-color: black;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  letter-spacing: 0.0001em;
  border: none;
  font-size: 23px;
  text-decoration: none;
  color: white;
  border-radius: 8px;
  transition: transform 0.3s;
  animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation-duration: 1s;
}

.main_page_home_linkdiv:hover {
  transform: scale(1.04);
}

.main_page_home_image {
  width: 93%;
  height: 93%;
  object-fit: fill;
  position: relative;
  border-radius: 20px;
  border: 1px solid grey;
  animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.main_page_home_imgdiv {
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  border-radius: 10px;
  position: relative;
  top: 13%;
  padding: 5%;
}

/* Main Page service */
.main_page_service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* margin-bottom: 50px; */
  padding-top: 5%;
}

.main_page_service_tag {
  display: flex;
  color: white;
  align-self: center;
  padding-inline: 2%;
  border: 1px solid white;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.main_page_service_text {
  width: 55%;
  position: relative;
  text-align: center;
  color: white;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.0001em;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 2%;
  -webkit-font-smoothing: antialiased;
}

.main_page_service_list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row: repeat(12, 1fr);
  grid-gap: 30px;
  width: 83%;
  height: 2000px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.main_page_service_list_item {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  text-align: left;
  justify-content: end;
  background-color: black;
  color: white;
  padding: 0px 40px;
  border-radius: 35px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  grid-column: span 5;
}

#main_page_list_item1 {
  background-image: url("../assets/images/enterprise-2.webp");
  background-size: cover;
  background-position: center;
}

.main_page_service_list_item:hover {
  transform: scale(1.04);
}

.main_page_service_listitem_h1 {
  font-size: 5.5rem;
  font-weight: 500;
  letter-spacing: 0.0001em;
  margin-bottom: 0px;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
}

.main_page_service_listitem_ptag {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: grey;
  margin-top: 0px;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;
  top: -20px;
  position: relative;
}

.main_page_service_list_item_longated {
  display: flex;
  color: white;
  flex-direction: column;
  line-height: 55px;
  text-align: left;
  background: rgb(133, 5, 149);
  background: linear-gradient(
    326deg,
    rgba(133, 5, 149, 1) 0%,
    rgba(44, 40, 168, 1) 100%,
    rgba(214, 92, 141, 1) 100%
  );
  padding: 35px;
  border-radius: 35px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  grid-column: span 7;
}

.main_page_service_list_item_longated:hover {
  transform: scale(1.04);
}

.main_page_service_list_item_title {
  font-size: 3.5rem;
  font-weight: 500;
  letter-spacing: 0.0001em;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;
}

.main_page_service_list_item_logodiv {
  width: 110px;
  height: 110px;
  border-radius: 76px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 5s linear infinite;
  background: rgb(238, 174, 202);
  background: linear-gradient(
    90deg,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  box-shadow: 0 0 10px rgba(148, 187, 233, 1), 0 0 100px rgba(148, 187, 233, 1),
    0 0;
}

.main_page_service_list_item_logo {
  width: 90px;
  height: 90px;
  object-fit: fill;
  position: relative;
  border-radius: 60px;
  border: none;
  padding: 19%;
  background-color: black;
  animation: spin-negative 5s linear infinite;
}

#main_page_service_list_item_extra {
  justify-content: space-around;
  padding-top: 5%;
}

.main_page_service_list_item_h1tag {
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  margin-bottom: 0px;
  -webkit-font-smoothing: antialiased;
}
.standards_h1tag {
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 0.0001em;
  margin-bottom: -60px;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  width: 80%;
  text-align: left;
}
.main_page_service_standards {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  width: 83%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 3%;
  gap: 20px;
}
.main_page_service_standards_item {
  display: flex;
  flex-direction: column;
  background: black;
  border-radius: 25px;
  border: none;
  width: 33.3%;
  padding: 45px;
  margin: 20px 0;
  color: #fff;
  text-align: center;
  justify-content: center;
  text-align: left;
  height: 250px;
  position: relative;
  gap: 10px;
}
.main_page_service_standards_item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 101%; */
  /* height: 100%; */
  background: linear-gradient(
    90deg,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  border-radius: 25px;
  z-index: -1;
  margin: -0.8px;
}

.main_page_service_standards_item_h1tag {
  font-size: 24px;
  margin-bottom: 10px;
  font-size: 2rem;
}

.main_page_service_standards_item_ptag {
  font-size: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-negative {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* Main Page Features */
.main_page_features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  padding-inline: 8%;
  padding-top: 5%;
}

.main_page_features_tag {
  display: flex;
  color: white;
  align-self: center;
  padding-inline: 3%;
  border: 1px solid white;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.main_page_pricing_tag {
  display: flex;
  color: white;
  align-self: center;
  padding-inline: 3%;
  border: 1px solid white;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.main_page_features_h1tag {
  display: flex;
  font-size: 4.5rem;
  align-self: center;
  justify-content: center;
  width: 90%;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.0001em;
  line-height: 5rem;
  margin-bottom: 0px;
  -webkit-font-smoothing: antialiased;
  margin-top: 30px;
}
.main_page_feature_1 {
  align-items: center;
  justify-content: space-between;
  color: white;
  /* margin-top: 60px;
    margin-bottom: 60px; */
  width: 100%;
  /* padding-inline: 8%; */
}

.main_page_feature_1_Text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  color: white;
  /* margin-top: 50px; */
  /* margin-bottom: 50px; */
  /* width: 40%; */
  padding-inline: 3%;
}
.main_page_feature_1_video {
  /* width: 60%; */
  height: 100%;
  object-fit: fill;
  position: relative;
  border-radius: 15px;
  border: none;
}
.main_page_feature_1_Text_h1 {
  margin: 0px 0px;
  margin-bottom: 1rem;
}
.main_page_feature_1_Text_ptag {
  /* font-size: 1rem; */
  margin-bottom: 40px;
  color: grey;
}
.main_page_contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: black;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.main_page_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  padding: 50px;
  width: 100%;
  height: 100%;
}
.main_page_footer {
  padding: 20px 0;
  text-align: center;
}

.main_page_footer_content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.main_page_footer_text {
  font-size: 14px;
  color: #6c757d;
  margin: 10px 0;
}

.main_page_footer_links {
  margin: -5px 0;
}

.main_page_footer_link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  font-size: 0.6rem;
}
.main_page_footer_company_link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  font-size: 1rem;
}

.main_page_footer_link:hover {
  text-decoration: underline;
}

.main_page_footer_poweredby {
  font-size: 14px;
  color: #6c757d;
  margin: 10px 0;
}

.main_page_footer_social {
  margin-top: 20px;
}

.main_page_footer_social .main_page_footer_link {
  margin: 0 10px;
  font-size: 16px;
}
.fab {
  font-size: 1.5rem;
}
.main_page_contactcard_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
  margin-bottom: 70px;
  color: white;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.main_page_contactcard {
  border: none;
  border-radius: 25px;
  padding: 25px;
  width: 80%;
  position: relative;
  background-color: black;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 200px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
}
.main_page_contactcard::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  /* width: 101%; */
  /* height: 100%; */
  background: rgb(0, 221, 242);
  background: linear-gradient(
    326deg,
    rgba(0, 221, 242, 1) 0%,
    rgba(43, 232, 106, 1) 54%,
    rgba(84, 93, 255, 1) 100%
  );
  border-radius: 27px;
  z-index: -1;
  margin: -1.8px;
}
.main_page_contactcard_button {
  background-color: transparent;
  border: 3px solid white;
  border-radius: 12px;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 700;
  margin: 4px 2px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: transform 0.5s;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.495);
}
.main_page_contactcard_button:hover {
  transform: scale(1.05);
}
.main_page_contactcard_ptag {
  font-size: 1.2rem;
  margin-top: 15px;
  margin-bottom: 10px;
}
.main_page_payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.main_page_payment_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: white;
  padding: 0px;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.main_page_payment_plan {
  border: none;
  border-radius: 25px;
  padding: 25px;
  width: 365px;
  height: 500px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);
  flex-direction: column;
  /* justify-content: center; */
  display: flex;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
}
.main_page_payment_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  color: white;
  padding: 80px 0px;
  width: 100%;
  height: 100%;
  background: rgb(22, 27, 34);
  background: linear-gradient(
    0deg,
    rgba(22, 27, 34, 1) 10%,
    rgba(48, 57, 207, 1) 53%,
    rgba(22, 27, 34, 1) 90%
  );
}
.main_page_payment_h1tag {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.0001em;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  width: 80%;
  justify-content: center;
}
.main_page_payment_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
}
.main_page_payment_plan_h1tag {
  font-size: 2rem;
  font-weight: 700;
  /* letter-spacing: 0.0001em; */
  -webkit-font-smoothing: antialiased;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  width: 100%;
  justify-content: center;
  text-align: left;
}
.main_page_payment_plan_description {
  font-size: 1rem;
  letter-spacing: 0.0001rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 0px;
  width: 80%;
  text-align: center;
  color: grey;
}
.main_page_payment_plan_ptag {
  font-size: 1.2rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 0px;
  width: 100%;
  text-align: center;
  color: grey;
}
.main_page_payment_plan_button {
  background-color: white;
  border: none;
  width: 86%;
  border-radius: 10px;
  color: black;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 4px 2px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: transform 0.5s;
}
.main_page_payment_plan_button:hover {
  transform: scale(1.05);
}
.main_page_payment_ptag {
  /* font-size: 1.2rem; */
  margin-top: 30px;
  width: 50%;
}
.main_page_payment_benifits {
  display: flex;
  font-size: 1rem;
  margin: 0px 0px;
  align-items: center;
}
.main_page_payment_benifits_div {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  margin-top: 30px;
  gap: 5px;
  text-align: left;
  color: white;
  padding: 0px;
  width: 100%;
  height: 30%;
}
/* .icon_style {
  color: "#63E6BE";
  width: 25px;
  height: 25px;
}
.icon2_style {
  size: 50px;
  width: 30;
} */
.main_page_payment_plan_div {
  width: 100%;
  text-align: left;
}
.main_page-payment_plan_span {
  font-size: 3rem;
  font-weight: 700;
}
@media (max-width: 2260px) {
  .main_page_feature_1 {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .main_page_feature_1_video {
    width: 60%;
    height: 100%;
    object-fit: fill;
    position: relative;
    border-radius: 15px;
    border: none;
  }
}
@media (max-width: 1800px) {
  .main_page_service_list {
    height: 1130px;
  }
  .main_page_service_list_item {
    grid-column: span 5;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.8rem;
  }
  .main_page_service_list_item_logodiv {
    width: 100px;
    height: 100px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 90px;
    height: 90px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.8rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 3.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 2.5rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 1.2rem;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 3.5rem;
    width: 70%;
  }
  .main_page_home_titletext {
    font-size: 3.5rem;
    width: 59%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 1rem;
    width: 22%;
  }
  .main_page_features_h1tag {
    font-size: 3.5rem;
    line-height: 3.5rem;
    width: 92%;
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .main_page_service_tag {
    padding-inline: 2%;
    font-size: 1.3rem;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: row;
  }
  .main_page_feature_1_Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 40%;
    padding-inline: 3%;
  }
  .main_page_feature_1_video {
    width: 60%;
    height: 100%;
    object-fit: fill;
    position: relative;
    border-radius: 15px;
    border: none;
  }
  .main_page_feature_1_Text_ptag {
    font-size: 0.8rem;
  }
  .main_page_features_tag {
    padding-inline: 3%;
    font-size: 1.2rem;
    border-radius: 15px;
  }
}
@media (max-width: 1440px) {
  .main_page_service_list_item {
    grid-column: span 5;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.8rem;
  }
  .main_page_service_list_item_logodiv {
    width: 100px;
    height: 100px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 90px;
    height: 90px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.8rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 3.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 2.5rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 1.2rem;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 3.5rem;
    width: 70%;
  }
  .main_page_home_titletext {
    font-size: 3.5rem;
    width: 59%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 1rem;
    width: 22%;
  }
  .main_page_features_h1tag {
    font-size: 3.5rem;
    line-height: 3.5rem;
    width: 92%;
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .main_page_service_tag {
    padding-inline: 2%;
    font-size: 1rem;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .main_page_feature_1_Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 40%;
    padding-inline: 3%;
  }
  .main_page_feature_1_video {
    width: 60%;
    height: 100%;
    object-fit: fill;
    position: relative;
    border-radius: 15px;
    border: none;
  }
  .main_page_feature_1_Text_ptag {
    font-size: 0.8rem;
  }
  .main_page_features_tag {
    padding-inline: 2%;
    font-size: 1rem;
    border-radius: 10px;
  }
  .main_page_payment_ptag {
    font-size: 1.2rem;
  }
  .main_page_payment_plan_h1tag {
    font-size: 2rem;
    width: 100%;
  }
  .main_page_payment_plan_ptag {
    font-size: 1.2rem;
  }
}
@media (max-width: 1200px) {
  .main_page_service_list {
    height: 1075;
  }
  .main_page_service_list_item {
    grid-column: span 5;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.8rem;
  }
  .main_page_service_list_item_logodiv {
    width: 100px;
    height: 100px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 90px;
    height: 90px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.8rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 3.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 2.5rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 1.2rem;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 2.5rem;
    width: 60%;
  }
  .main_page_home_titletext {
    font-size: 2.5rem;
    width: 50%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 1rem;
    width: 22%;
  }
  .main_page_features_h1tag {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_service_tag {
    padding-inline: 2%;
    font-size: 1rem;
  }
  .main_page_feature_1 {
    font-size: large;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .main_page_feature_1_Text_ptag {
    font-size: 0.8rem;
  }
  .main_page_payment_ptag {
    font-size: 1rem;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .main_page_service_list {
    height: 938px;
  }
  .main_page_service_list_item {
    grid-column: span 5;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.8rem;
  }
  .main_page_service_list_item_logodiv {
    width: 100px;
    height: 100px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 90px;
    height: 90px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.8rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 3.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 2.5rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 1.2rem;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 2.5rem;
    width: 60%;
  }
  .main_page_home_titletext {
    font-size: 2.5rem;
    width: 50%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 1rem;
    width: 22%;
  }
  .main_page_features_h1tag {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_service_tag {
    padding-inline: 2%;
    font-size: 1.3rem;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: row;
  }
  .main_page_feature_1_Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    color: white;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 70%;
    padding-inline: 3%;
  }
  .main_page_payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 83%;
    padding-inline: 8%;
    padding-top: 5%;
  }
  .main_page_payment_ptag {
    font-size: 0.8rem;
    width: 67%;
  }
  .main_page_payment_plan {
    height: 385px;
    width: 350px;
  }
  .main_page_payment_plan_h1tag {
    font-size: 1.8rem;
    width: 100%;
  }
  .main_page_payment_plan_ptag {
    font-size: 0.9rem;
  }
  .main_page_payment_plan_button {
    font-size: 1.2rem;
    padding: 15px 20px;
  }
  .main_page_payment_benifits {
    font-size: 0.8rem;
  }
}
@media (max-width: 992px) {
  .main_page_service_list {
    height: 897px;
    gap: 25px;
  }
  .main_page_service_list_item {
    grid-column: span 5;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.8rem;
  }
  .main_page_service_list_item_logodiv {
    width: 100px;
    height: 100px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 90px;
    height: 90px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.8rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 3.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 2.1rem;
    line-height: 2.3rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 1.2rem;
  }
  .main_page_service_list_item_longated {
    padding: 20px;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 2.5rem;
  }
  .main_page_home_titletext {
    font-size: 2.5rem;
    width: 61%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 1rem;
    width: 29%;
    height: 20px;
  }
  .main_page_features_h1tag {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_service_tag {
    padding-inline: 2%;
    font-size: 1.3rem;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .main_page_feature_1_Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    color: white;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 70%;
    padding-inline: 3%;
  }
  .main_page_feature_1_video {
    width: 90%;
    height: 100%;
    object-fit: fill;
    position: relative;
    border-radius: 15px;
    border: none;
  }
  #main_page_feature_alternative1 {
    flex-direction: column-reverse;
  }
  #main_page_feature_alternative2 {
    flex-direction: column-reverse;
  }
}
@media (max-width: 897px) {
  .main_page_service_list {
    height: 810px;
  }
  .main_page_service_list_item {
    grid-column: span 5;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.8rem;
  }
  .main_page_service_list_item_logodiv {
    width: 100px;
    height: 100px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 90px;
    height: 90px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.5rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 3.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 2.1rem;
    line-height: 2.3rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 1.2rem;
  }
  .main_page_service_list_item_longated {
    padding: 20px;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 2.5rem;
    width: 81%;
  }
  .main_page_home_titletext {
    font-size: 2.5rem;
    width: 67%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 1rem;
    width: 28%;
    height: 20px;
  }
  .main_page_features_h1tag {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_service_tag {
    padding-inline: 2%;
    font-size: 1.3rem;
  }
  #main_page_service_list_item_extra {
    justify-content: space-around;
    padding-top: 3%;
    padding: 0px 27px;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .main_page_feature_1_Text {
    font-size: medium;
  }
  .main_page_feature_1_Text_ptag {
    font-size: 0.6rem;
  }
  .standards_h1tag {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 0.0001em;
    margin-bottom: -60px;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: white;
    width: 80%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .main_page_service_list {
    height: 591px;
    gap: 15px;
  }
  .main_page_service_list_item {
    grid-column: span 5;
    border-radius: 25px;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.3rem;
    border-radius: 25px;
  }
  .main_page_service_list_item_logodiv {
    width: 60px;
    height: 60px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 55px;
    height: 55px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 2.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 0.5rem;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 1.8rem;
    width: 68%;
  }
  .main_page_home_titletext {
    font-size: 1.8rem;
    width: 57%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 0.8rem;
    width: 28%;
    margin-top: 0px;
  }
  .main_page_features_h1tag {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_service_tag {
    padding-inline: 1%;
    font-size: 1rem;
  }
  .main_page_feature_1_Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    color: white;
    margin-top: 50px;
    margin-bottom: 0px;
    width: 85%;
    padding-inline: 3%;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .main_page_features_tag {
    padding-inline: 2%;
    font-size: 1rem;
    border-radius: 10px;
  }
  .main_page_service_standards_item {
    height: 200px;
    padding: 15px;
  }
  .main_page_service_standards_item_h1tag {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  .main_page_service_standards_item_ptag {
    font-size: 0.8rem;
  }
  .standards_h1tag {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 0.0001em;
    margin-bottom: -60px;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: white;
    width: 80%;
    text-align: center;
  }
  .standards_h1tag {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0.0001em;
    margin-bottom: -60px;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: white;
    width: 80%;
    text-align: center;
  }
  .main_page_features_h1tag {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_payment_plan {
    height: 385px;
  }
  .main_page_payment_ptag {
    font-size: 0.7rem;
  }
  .main_page_payment_plan_h1tag {
    font-size: 1.5rem;
    width: 100%;
  }
  .main_page_payment_benifits_div {
    width: 70%;
  }
  .main_page_payment_benifits {
    font-size: 0.7rem;
  }
  .main_page_payment_plan_ptag {
    font-size: 0.7rem;
  }
  .main_page_payment_plan_button {
    font-size: 1rem;
    padding: 10px 15px;
  }
}
@media (max-width: 660px) {
  .main_page_service_list {
    height: 660px;
  }
  .main_page_service_list_item {
    grid-column: span 6;
  }
  .main_page_service_list_item_longated {
    grid-column: span 6;
    font-size: 1.8rem;
  }
  .main_page_service_list_item_logodiv {
    width: 55px;
    height: 55px;
    border-radius: 76px;
    margin-left: 10px;
  }
  .main_page_service_list_item_logo {
    width: 55px;
    height: 55px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.4rem;
  }
  #main_page_service_list_item_extra {
    justify-content: space-around;
    padding-top: 3%;
    padding: 0px 20px;
  }
  .main_page_service_listitem_h1 {
    font-size: 2.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 1.5rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 1.2rem;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 1.7rem;
    width: 74%;
  }
  .main_page_home_titletext {
    font-size: 1.8rem;
    width: 66%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 0.8rem;
    width: 31%;
    margin-top: 0px;
    height: 10px;
  }
  .main_page_features_h1tag {
    font-size: 2.5rem;
    line-height: 2.5rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_service_tag {
    padding-inline: 1%;
    font-size: 0.8rem;
    border-radius: 9px;
  }
  .standards_h1tag {
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 0.0001em;
    margin-bottom: -60px;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: white;
    width: 80%;
    text-align: center;
  }
  .main_page_payment_div {
    flex-direction: column;
  }
  .main_page_payment_plan {
    width: 280px;
  }
}
@media (max-width: 576px) {
  .main_page_service_list {
    height: 591px;
  }
  .main_page_service_list_item {
    grid-column: span 5;
    border-radius: 25px;
  }
  .main_page_service_list_item_longated {
    grid-column: span 7;
    font-size: 1.3rem;
    border-radius: 25px;
  }
  .main_page_service_list_item_logodiv {
    width: 60px;
    height: 60px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 55px;
    height: 55px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .main_page_service_listitem_h1 {
    font-size: 2.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 0.5rem;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 1.6rem;
    width: 80%;
  }
  .main_page_home_titletext {
    font-size: 1.8rem;
    width: 75%;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 0.5rem;
    width: 26%;
    margin-top: 0px;
    height: 0px;
  }
  .main_page_features_h1tag {
    font-size: 2rem;
    line-height: 2rem;
    width: 95%;
    margin-top: 25px;
  }
  .main_page_features {
    width: 100%;
    padding: 0px;
  }
  .main_page_features_tag {
    align-self: center;
    font-size: 0.8rem;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px;
    width: 100%;
  }
  .main_page_service_standards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-top: 3%;
    gap: 0px;
  }
  .main_page_service_standards_item {
    height: 150px;
    padding: 15px;
    width: 80%;
  }
  .main_page_service_standards_item_h1tag {
    font-size: 1rem;
  }
  .main_page_service_standards_item_ptag {
    font-size: 0.7rem;
  }
  .standards_h1tag {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.0001em;
    margin-bottom: -60px;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: white;
    width: 80%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .main_page_service_list {
    height: 440px;
    gap: 15px;
    width: 90%;
  }
  .main_page_service_list_item {
    grid-column: span 6;
    border-radius: 15px;
  }
  .main_page_service_list_item_longated {
    grid-column: span 6;
    font-size: 0.8rem;
    border-radius: 15px;
  }
  .main_page_service_list_item_logodiv {
    width: 39px;
    height: 39px;
    border-radius: 76px;
  }
  .main_page_service_list_item_logo {
    width: 36px;
    height: 36px;
    object-fit: fill;
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 19%;
    background-color: black;
  }
  .main_page_service_list_item_h1tag {
    font-size: 0.8rem;
    line-height: 1rem;
    margin-top: 20px;
  }
  .main_page_service_listitem_h1 {
    font-size: 2.5rem;
  }
  .main_page_service_list_item_title {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
  .main_page_service_listitem_ptag {
    font-size: 0.5rem;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .main_page_service_list_item_longated {
    font-size: 1.8rem;
    padding: 10px;
  }
  .main_page_service_list_item {
    font-size: 1.8rem;
  }
  .main_page_service_text {
    font-size: 1.5rem;
    width: 80%;
  }
  .main_page_home_titletext {
    font-size: 1.5rem;
    margin-bottom: 20px;
    width: 85%;
    margin-top: 24%;
  }
  .main_page_home {
    height: 100vh;
  }
  .main_page_home_text {
    font-size: 1.8rem;
  }
  .main_page_home_linkdiv {
    font-size: 0.5rem;
    width: 30%;
    margin-top: 0px;
    padding: 16px;
    border-radius: 5px;
  }
  .main_page_features_h1tag {
    width: 90%;
    position: relative;
    text-align: center;
    color: white;
    font-size: 1.5rem;
    align-self: center;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0001em;
    z-index: 1;
    margin-bottom: 5%;
    -webkit-font-smoothing: antialiased;
  }
  .main_page_home_image {
    border-radius: 10px;
    width: 100%;
  }
  .main_page_service_tag {
    padding-inline: 2%;
    font-size: 0.7rem;
  }
  #main_page_service_list_item_extra {
    justify-content: space-around;
    padding-top: 0%;
    padding: 0px 10px;
  }
  #main_page_list_item1 {
    padding: 0px 15px;
  }
  .main_page_feature_1 {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .main_page_contactcard_button {
    font-size: 1rem;
    padding: 8px 13px;
    border-radius: 7px;
  }
  .main_page_contactcard_ptag {
    font-size: 0.8rem;
  }
  /* .main_page_payment_section {
    padding: 0px;
    margin: 0px;
  } */
  .main_page_contactcard {
    height: 130px;
    width: 80%;
  }
  .main_page_home_imgdiv {
    top: 5%;
  }
  /* .main_page_payment_div {
    gap: 10px;
  } */
}
@media (max-width: 390px) {
  /* .main_page_payment_div {
    gap: 7px;
  }
  .main_page_payment_benifits_div {
    width: 90%;
  }
  .main_page_payment_plan_ptag {
    width: 101%;
  } */
}
