.navbar {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 500;
  /* padding-inline: 5%; */
  position: fixed;
  width: 97%;
  top: 0px;
  z-index: 100000;
}

.navbar_nav {
  display: flex;
  gap: 25px;
  align-items: center;
  padding: 7px 10px;
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
}

.navbar-brand {
  font-size: 1.7rem;
  line-height: 1.5rem;
  margin-bottom: 0;
  font-weight: 800;
  color: white;
  text-decoration: none;
  filter: drop-shadow(0px 0px 2px black);
}

.nav_list_items {
  color: white;
  text-decoration: none;
  list-style: none;
}

.nav_list_items li {
  list-style: none;
}

.nav-item {
  margin: 0 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  list-style: none;
  transition: 0.3s;
}

.nav-item:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: rgb(172, 172, 172);
  border-radius: 10px;
  transition: 0.3s;
}

.navbar_options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  list-style: none;
  padding: 0px;
}

.app_script_logo {
  height: 40px;
  margin-right: 10px;
  /* filter:drop-shadow(0px 0px 3px #CF66DA); */
  /* animation: glow 2s infinite alternate; */
}
@keyframes glow {
  0% {
    filter: drop-shadow(0px 0px 3px white);
  }
  100% {
    filter: drop-shadow(0px 0px 3px purple);
  }
}

.navbar_branding {
  display: flex;
  align-items: center;
}

.navbar_signin_button {
  background-color: black;
  color: black;
  padding: 8px 17px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  border: none;
  transition: transform 0.3s;
}
.navbar_dowload_button {
  text-decoration: none;
  color: white;
}

.navbar_signin_button:hover {
  transform: scale(1.04);
}

/* .navbar_login_button {
    background-color: black;
    color: white;
    padding: 8px 17px;
    border-radius: 25px;
    border: 2px solid black;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
}

.navbar_login_button:hover {
    background-color: white;
    color: black;
    transition: 0.3s;
} */

.navbar_buttons_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.hamburger_menu {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 0%;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .nav-item {
    margin: 0 5px;
    padding: 5px;
  }
  .app_script_logo {
    height: 30px;
    margin-right: 5px;
  }
  .navbar-brand {
    font-size: 1.3rem;
  }
}
@media (max-width: 480px) {
  .app_script_logo {
    height: 30px;
    margin-right: 3px;
  }
  .navbar-brand {
    font-size: 1.3rem;
  }
  .hamburger_menu {
    font-size: 1.5rem;
  }
  .navbar_nav.open {
    width: 37%;
    right: 17%;
    padding: 10px;
  }
  .navbar_login_button {
    padding: 8px 25px;
  }
}
@media (max-width: 946px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding: 20px 5%;
  }

  .navbar_branding {
    margin-bottom: 0;
  }
  .hamburger_menu {
    right: -8%;
  }

  .navbar_nav {
    flex-direction: column;
    gap: 15px;
    width: auto;
    display: none;
    position: absolute;
    top: 60px; /* Adjust this value based on your navbar height */
    right: 20px; /* Adjust this value based on your padding */
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
  }

  .navbar_nav.open {
    display: flex;
  }

  .navbar_options {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .navbar_buttons_div {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .hamburger_menu {
    display: block;
    position: relative;
    z-index: 1001;
  }
}
@media (max-width: 897px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
    /* padding-inline: 5%; */
    padding: 20px 5%;
  }

  .navbar_branding {
    margin-bottom: 0;
  }

  .navbar_nav {
    flex-direction: column;
    gap: 15px;
    width: auto;
    display: none;
    position: absolute;
    top: 60px; /* Adjust this value based on your navbar height */
    right: 20px; /* Adjust this value based on your padding */
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
  }

  .navbar_nav.open {
    display: flex;
  }

  .navbar_options {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .navbar_buttons_div {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .hamburger_menu {
    display: block;
    position: relative;
    z-index: 1001;
  }
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
    /* padding-inline: 5%; */
    padding: 20px 5%;
  }

  .navbar_branding {
    margin-bottom: 0;
  }

  .navbar_nav {
    flex-direction: column;
    gap: 15px;
    width: auto;
    display: none;
    position: absolute;
    top: 60px; /* Adjust this value based on your navbar height */
    right: 20px; /* Adjust this value based on your padding */
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
  }

  .navbar_nav.open {
    display: flex;
  }

  .navbar_options {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .navbar_buttons_div {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .hamburger_menu {
    display: block;
    position: relative;
    z-index: 1001;
  }
}
