@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #161b22;
}
html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  transition: 0.3s;
}
body {
  overflow-y: scroll; /* Ensures vertical scrolling */
  overscroll-behavior-y: contain; /* Prevents scroll chaining */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
