.contact {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 999999;
  font-family: sans-serif;
}
.left_section {
  display: flex;
  width: 50%;
  height: 100vh;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(22, 27, 34);
  background: radial-gradient(
    circle,
    rgba(22, 27, 34, 1) 0%,
    rgba(77, 8, 172, 1) 0%,
    rgba(21, 27, 36, 1) 94%
  );
}
.h1_tag_branding {
  color: #ffffff;
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: 1rem;
  text-align: left;
  font-family: sans-serif;
}
.p_tag_branding {
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  padding: 0px 80px;
}
.app_script_logo_branding {
  width: 85px;
  height: 90px;
  margin-top: 1rem;
}
.right_section {
  display: flex;
  width: 50%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.contact-form {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.h1_tag {
  color: #000000;
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: 1rem;
  text-align: left;
}
.p_tag {
  color: #000000;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  text-align: left;
}
.label_text {
  width: 70%;
  color: #000000;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
}
.input_field {
  width: 70%;
  height: 2rem;
  margin-bottom: 1rem;
  padding: 0.3rem;
  border: 1px solid #767575;
  border-radius: 5px;
}
.text_field {
  width: 70%;
  height: 5rem;
  margin-bottom: 1rem;
  padding: 0.3rem;
  border: 1px solid #767575;
  border-radius: 5px;
  background-color: white;
}
.button_div {
  display: flex;
  width: 70%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.cancel_button {
  display: flex;
  text-align: center;
  align-items: center;
  width: 7rem;
  height: 2.5rem;
  margin-right: 1rem;
  background-color: #f7f7f7;
  align-self: center;
  text-decoration: none;
  justify-content: center;
  color: #000000;
  border: 1px solid #767575;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.cancel_button:hover {
  scale: 1.05;
}
.submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 2.5rem;
  background: rgb(22, 27, 34);
  background: radial-gradient(
    circle,
    rgba(22, 27, 34, 1) 0%,
    rgba(101, 20, 214, 1) 0%,
    rgba(21, 27, 36, 1) 150%
  );
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.submit_button:hover {
  scale: 1.05;
}

@media (max-width: 480px) {
  .left_section {
    width: 0%;
  }
  .h1_tag_branding {
    font-size: 0rem;
  }
  .right_section {
    width: 100%;
  }
  .h1_tag {
    font-size: 2rem;
  }
  .p_tag {
    font-size: 1rem;
  }
  .label_text {
    font-size: 0.8rem;
  }
  .button_div {
    justify-content: center;
  }
}
