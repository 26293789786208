.changelog_page {
  min-height: 100vh;
  padding: 4rem 2rem;
  background: linear-gradient(to bottom, #0f172a, #1e293b);
}

.changelog_page_h1tag {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 3rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* .changelog-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 3rem 2rem;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

.changelog-container::before {
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  top: 0;
  height: 100%;
  width: 2px;
  background: linear-gradient(
    to bottom,
    transparent,
    #6366f1 10%,
    #8b5cf6 90%,
    transparent
  );
  opacity: 0.3;
}

.changelog-item {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  width: calc(50% - 3rem);
  margin-left: auto;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.changelog-item:nth-child(even) {
  margin-right: auto;
  margin-left: 0;
}

.changelog-item::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #8b5cf6;
  border-radius: 50%;
  right: -3.5rem;
  top: 2.5rem;
  box-shadow: 0 0 0 4px rgba(139, 92, 246, 0.2);
}

.changelog-item:nth-child(even)::before {
  right: auto;
  left: -3.5rem;
}

.changelog-item:hover {
  transform: translateY(-4px);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.version-badge {
  padding: 0.6rem 1.2rem;
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  border-radius: 25px;
  color: white;
  font-weight: 700;
  font-size: 0.95rem;
  white-space: nowrap;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(99, 102, 241, 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.changelog-content {
  flex: 1;
}

.changelog-content p {
  margin: 0;
  color: #e2e8f0;
  font-size: 1.05rem;
  line-height: 1.7;
  letter-spacing: 0.2px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .changelog-container::before {
    left: 2rem;
  }

  .changelog-item {
    width: calc(100% - 4rem);
    margin-left: 4rem !important;
  }

  .changelog-item::before {
    left: -2rem !important;
    right: auto !important;
  }

  .changelog-item {
    padding: 1.5rem;
    gap: 1.25rem;
  }

  .version-badge {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .changelog-content p {
    font-size: 1rem;
  }
} */


.changelog-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.changelog-item {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.changelog-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

.version-badge {
  padding: 0.5rem 1rem;
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  border-radius: 20px;
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
  white-space: nowrap;
}

.changelog-content {
  flex: 1;
}

.changelog-content p {
  margin: 0;
  color: #e2e8f0;
  font-size: 1rem;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .changelog-container {
    padding: 1rem;
  }

  .changelog-item {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .version-badge {
    align-self: flex-start;
  }
}
